<template>
  <div :class="{ 'my-40 p-10 bg-black -mx-10 md:p-20': border && !main, 'p-10 bg-black': main }">
    <h2 class="font-khand font-bold text-28 leading-32 mb-5 text-white" v-if="componentContent.gallery.title && title">{{ componentContent.gallery.title }}</h2>
    <button @click="galleryOpened = true" class="relative w-full">
      <AllerImgixImg v-if="componentContent.gallery && componentContent.gallery.media && componentContent.gallery.media[0]" :media="componentContent.gallery.media[0]" :sizes="imgSizing ? imgSizing : main ? '(max-width: 768px) calc(100vw - 40px), 860px' : '(max-width: 768px) calc(100vw - 40px), 480px'" :modifiers="{ ar: '16:9' }" width="300" height="169" :alt="componentContent.gallery.media[0]?.alt || ''" :render-widths="[300, 360, 410, 650, 1280, 1440]" :class="main ? 'rounded-t-10' : 'rounded-10'" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="square-130 flex flex-col items-center justify-center p-10 bg-red-600">
          <div class="font-khand font-bold text-20 leading-22 mb-5 text-white uppercase">Se galleri</div>
          <i class="square-80 bg-svg-gallery-white bg-center bg-no-repeat"></i>
        </div>
      </div>
    </button>
    <LazyFullscreenGallery v-if="galleryOpened" :gallery="componentContent.gallery" :opened="galleryOpened" :render-widths="renderWidths" @close="galleryOpened = false" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  componentContent: {
    type: Object as PropType<Aller.ContentComponentGallery>,
    required: true,
  },
  title: {
    type: Boolean,
    default: true,
  },
  border: {
    type: Boolean,
    default: true,
  },
  main: {
    type: Boolean,
    default: false,
  },
  imgSizing: {
    type: String,
  },
  lazyImg: {
    type: String,
    default: 'lazy',
  },
  renderWidths: {
    type: Array as PropType<number[]>,
    default: () => [320, 360, 380, 390, 410, 1020, 1080, 1280, 1440, 1900],
  },
});

const galleryOpened = ref(false);

const buildUrlSrc = (media: Aller.Media, modifiers: any) => {
  const { mediaSrc, defaultModifiers } = useAllerMedia(media);
  const img = useImage();

  return props.renderWidths.map((width: number) => {
    const mods = { ...defaultModifiers, ...modifiers, ...{ w: width } };
    return img(mediaSrc, mods) + ' ' + width + 'w';
  });
};

/**
 * Preload image.
 */
const preload = (index: number) => {
  const item = props.componentContent.gallery.media[index];
  const windowW = window.outerWidth;
  const arCalc = windowW > 1279 ? '1050:750' : windowW > 1109 ? '800:650' : windowW > 1023 ? '474:550' : windowW > 767 ? '500:357' : windowW > 399 ? '320:464' : '320:364';
  const srcset = buildUrlSrc(item, { fit: 'contain', ar: arCalc, fill: 'blur' }).join(' ,');
  const preload = document.createElement('link');
  preload.id = `gallery-init-preload-${(index).toString()}`;
  preload.as = 'image';
  preload.rel = 'preload';
  preload.imageSrcset = srcset;
  preload.imageSizes = '100vw';
  if (!document.getElementById(`gallery-init-preload-${(index).toString()}`)) {
    document.head.append(preload);
  }
};

/**
 * Vue mounted hook.
 *
 * @returns void
 */
onMounted((): void => {
  // Preload the first image in gallery.
  preload(0);
});
</script>